<template>
  <div class="home-page">
    <div class="home-container">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <Tabbar
      v-model="tabActive"
      active-color="#FFFFFF"
      inactive-color="#DBF2FC"
      style="background: url('/images/tabbar-bg.png') no-repeat 100%;  background-size: cover;"
    >
      <TabbarItem
        v-for="(item, index) of tabIconData"
        :key="index"
        @click="tabChange(item, index)"
      >
        {{ item.name }}
        <template #icon="props">
          <img :src="props.active ? item.inactive : item.active" />
        </template>
      </TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  components: { Tabbar, TabbarItem },
  props: {},
  data() {
    return {
      tabActive: 0,
      tabIconData: [
        {
          name: "Home",
          active: "/images/icon/ic_home.png",
          inactive: "/images/icon/ic_home_active.png",
          url: "/",
        },
        {
          name: "Projects",
          active: "/images/icon/ic_projects.png",
          inactive: "/images/icon/ic_projects_active.png",
          url: "/project",
        },
        // {
        //   name: 'Task',
        //   active: '/images/icon/ic_task.png',
        //   inactive: '/images/icon/ic_task_active.png',
        //   url: '/task'
        // },
        {
          name: "Tram",
          active: "/images/icon/ic_tram.png",
          inactive: "/images/icon/ic_tram_active.png",
          url: "/tram",
        },
        {
          name: "User",
          active: "/images/icon/ic_user.png",
          inactive: "/images/icon/ic_user_active.png",
          url: "/user",
        },
      ],
    };
  },
  watch: {
    "$route.path": function (to, from) {
      switch (to) {
        case "/":
          this.tabActive = 0;
          break;
        case "/project":
          this.tabActive = 1;
          break;
        // case "/task":
        //   this.tabActive = 2;
        //   break;
        case "/tram":
          this.tabActive = 2;
          break;
        case "/user":
          this.tabActive = 3;
          break;
        default:
          break;
      }
      console.log(`to:${to}, from:${from}`);
    },
  },
  computed: {},
  methods: {
    tabChange(item) {
      this.$router.push(item.url);
    },
  },
  created() {},
  mounted() {
    const path = this.$route.path;
    switch (path) {
      case "/":
        this.tabActive = 0;
        break;
      case "/project":
        this.tabActive = 1;
        break;
      case "/task":
        this.tabActive = 2;
        break;
      case "/tram":
        this.tabActive = 3;
        break;
      case "/user":
        this.tabActive = 4;
        break;
      default:
        break;
    }
  },
};
</script>

<style lang="less" scoped>
.home-page {
  width: 100%;

  background: #dbf2fc;
  .home-container {
    padding-bottom: 88px;
  }
  /deep/.van-tabbar {
    height: auto;
    border-radius: 20px 20px 0 0;
    padding: 16px 0;
    &::after {
      border-width: 0;
    }
    .van-tabbar-item {
      .van-tabbar-item__icon {
        img {
          width: auto;
          height: 38px;
        }
      }
      .van-tabbar-item__text {
        margin-top: 6px;
      }
    }
  }
}
</style>
