<template>
  <div
    class="market-page"
    style="
      background-image: url('/images/project1-bg.png');
      background-size: cover;
    "
  >
    <div class="header">
      <NavBar :border="false" placeholder safe-area-inset-top>
        <template #left>
          <Icon name="arrow-left" @click="goBack" size="22" />
        </template>
        <template #right>
          <div class="right-btn">
            <Icon name="/images/porse-icon.png" size="16" /><span class="span"
              >Recharge</span
            >
          </div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div class="page-title">{{ orderInfo?.title }}</div>
      <div class="project-wrapper">
        <div class="cell_group">
          <div class="cell-item">
            <div class="cell-title">Invest amount:</div>
            <div class="cell-value">Rs.{{ orderInfo?.min }}</div>
          </div>
          <div class="cell-item">
            <div class="cell-title">Hourly income:</div>
            <div class="cell-value">Rs.{{ orderInfo?.rate }}</div>
          </div>
          <div class="cell-item">
            <div class="cell-title">Daily income:</div>
            <div class="cell-value">Rs.{{ orderInfo?.dailyIncome }}</div>
          </div>
          <div class="cell-item">
            <div class="cell-title">Total income:</div>
            <div class="cell-value">Rs.{{ orderInfo?.totalIncome }}</div>
          </div>
          <div class="cell-item">
            <div class="cell-title">Invest term:</div>
            <div class="cell-value">{{ orderInfo?.day }}days</div>
          </div>
          <div class="cell-item">
            <div class="cell-title">Investable quantity</div>
            <div class="cell-value">
              {{ orderInfo?.usum }}/{{ orderInfo?.num }}
            </div>
          </div>
        </div>
        <div v-if="orderInfo?.state == 1" class="alert_wrapper">
          <div class="alert-item">
            Invest in Starlink to gain equipment benefits. After the investment
            is successful, the equipment will automatically generate income
            every hour.
          </div>
          <div class="alert-item">
            The longer the equipment benefits, the higher the benefits. In order
            to give you a better understanding of how equipment revenue is
            obtained, you can consult customer service personnel for details.
          </div>
        </div>
        <div v-if="orderInfo?.state == 2" class="alert_wrapper">
          <div class="alert-item">
            This equipment is currently being voted for. In order to test the
            satisfaction of the members with this equipment, please cast a
            satisfactory vote.
          </div>
        </div>
        <div class="btn-wrapper" v-if="orderInfo?.state == 1">
          <div class="btn-1">
            <Button
              @click="handlePlay"
              round
              style="
                background-image: url('/images/w-600.png');
                background-size: cover;
              "
            >
              Investable quantity: {{ orderInfo?.usum }}/{{ orderInfo?.num }}
            </Button>
          </div>
          <div class="btn-2">
            <Button @click="goBack" round> Cancel </Button>
          </div>
        </div>
        <div class="page-foot" v-if="orderInfo?.state == 2">
          <Button
            class="foot-btn"
            round
            @click="handleVote(1)"
            style="
              background-image: url('/images/buttom-bg.png');
              background-size: cover;
            "
          >
            <div>
              <div>Yes</div>
              <div class="vote-desc">
                {{ orderInfo?.vote }}
                <span class="vote-rate">({{ orderInfo.voteRate }}%)</span>
              </div>
            </div>
          </Button>
          <Button
            round
            class="foot-btn"
            @click="handleVote(0)"
            style="
              background-image: url('/images/bg_sellstatus_notstart.png');
              background-size: cover;
            "
          >
            <div>
              <div>No</div>
              <div class="vote-desc">
                {{ orderInfo?.fvote
                }}<span class="vote-rate"> ({{ orderInfo.fvoteRate }}%)</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, NavBar, Button, Toast } from "vant";
export default {
  name: "homeProject",
  components: {
    Icon,
    NavBar,
    Button,
    Toast,
  },
  data() {
    return {
      orderId: "",
      orderInfo: null,
    };
  },
  mounted() {
    this.orderId = this.$route?.query?.orderId || "";
    this.getOrderInfo();
  },
  methods: {
    onTabClick(item, index) {
      this.tabActive = index;
    },
    getOrderInfo() {
      this.$axios({
        url: "/api/project/item_detail",
        method: "POST",
        data: {
          id: this.orderId,
        },
      }).then((res) => {
        if (res?.code == 1) {
          this.orderInfo = res?.data || {};
          this.orderInfo.dailyIncome = Number(this.orderInfo.rate * 12).toFixed(
            2
          );
          this.orderInfo.totalIncome = Number(
            this.orderInfo.rate * 12 * this.orderInfo.day
          ).toFixed(2);
          if (res?.data?.state == 2) {
            if (this.orderInfo.vote == 0 && this.orderInfo.fvote == 0) {
              this.orderInfo.voteRate = 50;
              this.orderInfo.fvoteRate = 50;
            } else if (this.orderInfo.vote == 0 && this.orderInfo.fvote != 0) {
              this.orderInfo.voteRate = 0;
              this.orderInfo.fvoteRate = 100;
            } else if (this.orderInfo.vote != 0 && this.orderInfo.fvote == 0) {
              this.orderInfo.voteRate = 100;
              this.orderInfo.fvoteRate = 0;
            } else {
              let total = this.orderInfo.vote + this.orderInfo.fvote;
              this.orderInfo.voteRate =
                Number(this.orderInfo.vote / total).toFixed(4) * 100;
              this.orderInfo.fvoteRate = Number(
                100 - this.orderInfo.voteRate
              ).toFixed(2);
            }
          }
        }
      });
    },
    // 投票接口
    handleVote(type) {
      this.$axios({
        url: "/api/project/item_vote",
        method: "POST",
        data: {
          id: this.orderId,
          falg: type,
        },
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          Toast(res?.msg || "success");
          this.getOrderInfo();
        }
      });
    },
    // 购买产品
    handlePlay() {
      this.$axios({
        url: "/api/project/itemPlay",
        method: "POST",
        data: {
          id: this.orderId,
        },
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          Toast(res?.msg || "success");
          this.getOrderInfo();
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.page-foot {
  margin-top: 50px;
  padding-bottom: 30px;
  display: flex;
  gap: 20px;
  .foot-btn {
    flex: 1;
    height: 130px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .vote-desc {
      font-weight: bold;
      font-size: 40px;
      .vote-rate {
        font-weight: 400;
        font-size: 30px;
      }
    }
  }
}
</style>
