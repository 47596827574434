<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">Sign in</div>
        </template>
      </NavBar>
    </div>
    <!-- <div class="wrapper">
      <div class="wrapper-cell">
        <div class="cell-left">
          <div class="item-subtitle">My USDT</div>
          <div class="item-title">Rs.4000.00</div>
        </div>
      </div>
      <div
        class="signin-wrapper"
        style="
          background-image: url('/images/date-bg-1.png');
          background-size: cover;
        "
      >
        <div class="signin-title">
          Continuous check-ins <span class="span">2</span> days
        </div>
        <div class="signin-content">
          <div class="signin-list">
            <div class="signin-items">
              <div class="number">+10</div>
              <div class="icon">
                <Icon name="/images/sign-bi-icon.png" size="25" />
              </div>
            </div>
            <div class="date">09-18</div>
          </div>
          <div class="signin-list">
            <div class="signin-items">
              <div class="number">+10</div>
              <div class="icon">
                <Icon name="/images/sign-bi-icon.png" size="25" />
              </div>
            </div>
            <div class="date">09-20</div>
          </div>
          <div class="signin-list">
            <div class="signin-items">
              <div class="number">+30</div>
              <div class="icon">
                <Icon name="/images/sign-bi-icon.png" size="25" />
              </div>
            </div>
            <div class="date">09-21</div>
          </div>
          <div class="signin-list">
            <div class="signin-items active">
              <div class="number">+10</div>
              <div class="icon">
                <Icon name="/images/sign-icon.png" size="25" />
              </div>
            </div>
            <div class="date today">Today</div>
          </div>
          <div class="signin-list">
            <div class="signin-items">
              <div class="number">+30</div>
              <div class="icon">
                <Icon name="/images/sign-icon.png" size="25" />
              </div>
            </div>
            <div class="date">09-23</div>
          </div>
          <div class="signin-list">
            <div class="signin-items">
              <div class="number">+50</div>
              <div class="icon">
                <Icon name="/images/sign-icon.png" size="25" />
              </div>
            </div>
            <div class="date">09-24</div>
          </div>
          <div class="signin-list">
            <div class="signin-items">
              <div class="number">+50</div>
              <div class="icon">
                <Icon name="/images/sign-icon.png" size="25" />
              </div>
            </div>
            <div class="date">09-25</div>
          </div>
        </div>
      </div>
      <div class="tips">
        <div class="tips-title">Event Description</div>
        <div class="tips-list">
          After the sign-in is successful, the corresponding rewardswill be
          distributed to the login account 5 days after thesign-in.
        </div>
        <div class="tips-list">
          The fnal interpretation right of the check-in activity belongsto the
          platform within the scope permitted by law.
        </div>
      </div>
    </div> -->

    <div class="wrapper">
      <div class="sign-info">
        <div class="sign-info-title">Total:</div>
        <div class="sign-info-desc">{{ formDetail.sign_count }} Days</div>
      </div>
      <div class="sign-info">
        <div class="sign-info-title">Today Rebates:</div>
        <div class="sign-info-desc">{{ formDetail.sign_money }}</div>
      </div>
      <div class="sign-info">
        <div class="sign-info-title">Total Rebates:</div>
        <div class="sign-info-desc">{{ formDetail.sign_sum }}</div>
      </div>
      <div class="sign-info">
        <div class="sign-info-title">Status:</div>
        <div class="sign-info-desc">
          {{ formDetail.is_sign == 0 ? "No sign in" : "Had signed in" }}
        </div>
      </div>
    </div>

    <div class="footer" v-if="formDetail.is_sign == 0">
      <div class="footer-right">
        <Button
          @click="onSubmit"
          round
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
        >
          Sign in now
        </Button>
      </div>
    </div>
    <!-- <Popup v-model="show" round>
      <div class="popup-container">
        <div class="title">
          <img class="title-icon" src="/images/popup-bg.png" alt="" />
        </div>
        <div class="popup-body">
          <div class="tips-title">Sign in successfully</div>
          <div class="content">+10 USDT</div>
          <div class="btn">
            <Button
              round
              style="
                background-image: url('/images/w-600.png');
                background-size: cover;
              "
            >
              I see
            </Button>
          </div>
        </div>
      </div>
    </Popup> -->
    <Overlay :show="showLoading">
      <div class="Loadingwrapper">
        <Loading type="spinner" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import { Button, Icon, NavBar, Popup, Overlay, Loading } from "vant";
export default {
  name: "homeProject",
  components: {
    Button,
    Icon,
    NavBar,
    Popup,
    Overlay,
    Loading,
  },
  props: {},
  data() {
    return {
      show: true,
      formDetail: {
        sign_money: "0", //签到金额
        is_sign: 0, //0为今日未签到
        sign_count: 0, //总签到次数
        sign_sum: 0, //总签到金额
      },
      showLoading: false,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // 获取详情
      this.$axios({
        url: "/api/user/signin_page",
        method: "GET",
        data: {},
      }).then((res) => {
        let data = res.data;
        this.formDetail = data;
      });
    },
    onSubmit() {
      this.showLoading = true;
      this.$axios({
        url: "/api/user/signin",
        method: "POST",
      })
        .then((res) => {
          console.log(res);
          this.showLoading = false;
          this.getDetail();
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.sign-info {
  display: flex;
  font-size: 35px;
  margin-top: 20px;
  .sign-info-desc {
    margin-left: 10px;
    font-size: 35px;
    color: #93c5f8;
  }
}
</style>
