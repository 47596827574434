<template>
  <div class="market-page">
    <div
      class="header"
      style="
        background-image: url('/images/user-bg.png');
        background-size: cover;
      "
    >
      <NavBar :border="false" placeholder safe-area-inset-top></NavBar>
      <Cell :border="false" @click="$router.push('/user/login')">
        <template #icon>
          <div class="cell-icon">
            <img class="avatar" src="/images/avatar.png" alt="" />
          </div>
        </template>
        <template #title>
          <div class="title">Mobile:{{ formDetail.mobile }}</div>
        </template>
        <template #label>
          <div class="label">Account Balance:Rs.{{ formDetail.money }}</div>
        </template>
      </Cell>
      <Cell :border="false">
        <div class="cell-value">
          Problems encountered during use?To complain
        </div>
        <template #right-icon>
          <Icon name="arrow" size="16" />
        </template>
      </Cell>
    </div>
    <div class="wrapper">
      <div
        class="grid"
        style="
          background-image: url('/images/grid-bg.png');
          background-size: cover;
        "
      >
        <Grid :column-num="3" :border="false">
          <GridItem @click="$router.push('/user/recharge')">
            <img class="grid-img" src="/images/recharge.png" alt="" />
            <div class="grid-text">Recharge</div>
            <div class="line"></div>
          </GridItem>
          <GridItem @click="$router.push('/user/withdrawal')">
            <img class="grid-img" src="/images/withdraw.png" alt="" />
            <div class="grid-text">Withdraw</div>
          </GridItem>
          <GridItem @click="$router.push('/user/backAccount')">
            <img class="grid-img" src="/images/bank.png" alt="" />
            <div class="grid-text">Bank Account</div>
          </GridItem>
        </Grid>
      </div>
      <div class="cell-wrapper">
        <CellGroup :border="false" center>
          <Cell :border="false" @click="$router.push('/user/bill')">
            <template #icon>
              <Icon name="/images/my_bill.png" size="36" />
            </template>
            <template #title>
              <div class="title">My bill</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false" @click="$router.push('/user/article')">
            <template #icon>
              <Icon name="/images/make_money.png" size="36" />
            </template>
            <template #title>
              <div class="title">How to make money</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false" @click="$router.push('/user/signIn')">
            <template #icon>
              <Icon name="/images/sign_in.png" size="36" />
            </template>
            <template #title>
              <div class="title">Sign in</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false" @click="$router.push('/user/customerService')">
            <template #icon>
              <Icon name="/images/customer_service.png" size="36" />
            </template>
            <template #title>
              <div class="title">Customer service</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false" @click="$router.push('/user/articleAbout')">
            <template #icon>
              <Icon name="/images/about_us.png" size="36" />
            </template>
            <template #title>
              <div class="title">About us</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false" @click="$router.push('/user/password')">
            <template #icon>
              <Icon name="/images/login_password.png" size="36" />
            </template>
            <template #title>
              <div class="title">Login password</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false">
            <template #icon>
              <Icon name="/images/sign_out.png" size="36" />
            </template>
            <template #title>
              <div class="title" @click="goOut">Sign out</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
          <Cell :border="false">
            <template #icon>
              <Icon name="/images/download_apk.png" size="36" />
            </template>
            <template #title>
              <div class="title">Download Apk</div>
            </template>
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Cell>
        </CellGroup>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Progress,
  Grid,
  GridItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Search,
  DropdownMenu,
  DropdownItem,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Progress,
    Grid,
    GridItem,
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Search,
    DropdownMenu,
    DropdownItem,
  },
  data() {
    return {
      formDetail: {
        user_id: "",
        nickname: "", //昵称
        mobile: "", //电话号码
        money: "", //余额
        my_lx: 0,
        my_yj: 0,
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // 获取详情
      this.$axios({
        url: "/api/my/index",
        method: "GET",
      }).then((res) => {
        console.log(res);
        let data = res.data;
        this.formDetail = Object.assign(this.formDetail, data);
        localStorage.setItem("moneyInfo", JSON.stringify(data));
      });
    },
    goOut() {
      localStorage.clear();
      this.$router.push("/user/login");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
