<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">Withdrawal</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <Form>
        <div class="form-item">
          <div class="cell">
            <Cell :border="false">
              <template #title>
                <div class="cell-title">Amount</div>
              </template>
              <template #right-icon>
                <Icon name="/images/tixian-icon.png" size="20" />
              </template>
              <div class="label-right">Withdrawal Records</div>
            </Cell>
          </div>
          <Field
            class="txt-field"
            v-model="params.amount"
            placeholder="Please Input Original Password"
          >
            <template #right-icon>
              <div class="right-icon-text">Rs</div>
            </template>
          </Field>
          <div class="field-tips">
            <div class="field-tips-item">Amount that can be withdrawn</div>
            <div class="field-tips-item1">Rs {{ user_money }}</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label">Bank Card</div>
          <Field
            class="txt-field"
            v-model="params.bank_card"
            readonly
            placeholder="Select Bank Card"
            @click="bankChoose"
          >
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Login password</div>
          <Field
            class="txt-field"
            v-model="params.login_password"
            placeholder="Please Input Password"
            type="password"
          >
          </Field>
        </div>
      </Form>
      <div class="tips">
        <div class="tips-title">Specifications</div>
        <div class="tips-list">1: Withdrawal time: 10:00-18:00</div>
        <div class="tips-list">
          2: The withdrawal password is the account login password
        </div>
        <div class="tips-list">
          3: The minimum withdrawal amount is 200RS, and you can successfully
          withdraw once a day.
        </div>
        <div class="tips-list">
          4: To activate the qualification for cash withdrawal, you need to
          purchase equipment by yourself, or first-level members can activate
          cash withdrawal by purchasing equipment.
        </div>
      </div>
      <div class="form-btn">
        <Button
          :disabled="showLoading"
          @click="onSubmit"
          round
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
        >
          Continue
        </Button>
      </div>
    </div>
    <ActionSheet
      v-model="showMethod"
      :actions="methodActions"
      @select="onSelect"
    />
    <Overlay :show="showLoading">
      <div class="Loadingwrapper">
        <Loading type="spinner" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import {
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  Field,
  Form,
  ActionSheet,
  Loading,
  Overlay,
  Toast,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    Field,
    Form,
    ActionSheet,
    Overlay,
    Loading,
  },
  props: {},
  data() {
    return {
      showLoading: false,
      showMethod: false,
      params: {
        amount: "",
        bank_card: "",
        bank_id: "",
        login_password: "",
      },
      methodActions: [],
      user_money: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    bankChoose() {
      if (this.methodActions.length) {
        this.showMethod = true;
      } else {
        this.$route.push({
          path: "/user/backAccount",
        });
      }
    },
    getDetail() {
      // 获取详情
      this.$axios({
        url: "/api/money/withdraw_page",
        method: "GET",
        data: {},
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          let data = res.data;
          this.user_money = data.user_money;
          if (data?.bank_card?.length > 0) {
            this.methodActions = data.bank_card.map((item) => {
              item.name = item.bank_name;
              return item;
            });
            console.log(this.methodActions);
            this.params.bank_card = this.methodActions[0].name;
            this.params.bank_id = this.methodActions[0].id;
          } else {
            this.$router.push("/user/backAccount");
          }
        }
      });
    },
    onSelect(item) {
      console.log(item);
      this.params.bank_card = item.name;
      this.params.bank_id = item.id;
      this.showMethod = false;
    },
    onSubmit() {
      if (!this.params.amount) {
        Toast("Amount is required");
        return;
      }
      if (!this.params.bank_card) {
        Toast("Bank Name is required");
        return;
      }
      if (!this.params.login_password) {
        Toast("Login password is required");
        return;
      }
      this.showLoading = true;
      let data = {
        amount: this.params.amount, //金额
        card_id: this.params.bank_id, //所选银行卡ID
        password: this.params.login_password, //登录密码
      };
      this.$axios({
        url: "/api/money/withdraw",
        method: "POST",
        data,
      })
        .then((res) => {
          if (res?.code == 1) {
            Toast(res?.msg);
            this.showLoading = false;
            this.$router.back();
          } else {
            this.showLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showLoading = false;
        });
    },
    onLeftClick() {},
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
