<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">Bank Account</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <Form>
        <div class="form-item">
          <div class="label">Bank Name</div>
          <!-- <div class="label">Withdrawal method</div> -->
          <!-- <Field
            class="txt-field"
            v-model="params.method"
            readonly
            placeholder="Please Input Original Password"
            @click="showMethod = true"
          >
            <template #right-icon>
              <Icon name="arrow" size="22" color="#565964" />
            </template>
          </Field> -->
          <Field
            class="txt-field gap"
            v-model="params.bank_name"
            placeholder="Please enter bank card name"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Ifsc Code</div>
          <Field
            class="txt-field"
            v-model="params.ifsc_code"
            placeholder="Please input Ifsc Code"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Actual Name</div>
          <Field
            class="txt-field"
            v-model="params.actual_name"
            placeholder="Please input Actual Name"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Bank Account</div>
          <Field
            class="txt-field"
            v-model="params.bank_account"
            placeholder="Please input the Bank Account"
          >
          </Field>
        </div>
        <!-- <div class="form-item">
          <div class="label">Verifcation Code</div>
          <Field
            class="txt-field"
            v-model="params.code"
            placeholder="Please Input Verification Code"
          >
            <template #right-icon>
              <div class="right-icon-btn">
                <Button
                  style="
                    background-image: url('/images/btn-bg1.png');
                    background-size: cover;
                  "
                >
                  Get
                </Button>
              </div>
            </template>
          </Field>
        </div> -->
      </Form>
      <div class="tips">
        <div class="tips-title">Specifications</div>
        <div class="tips-list">1: Withdrawal time: 10:00-18:00</div>
        <div class="tips-list">
          2: The withdrawal password is the account login password
        </div>
        <div class="tips-list">
          3: The minimum withdrawal amount is 200RS, and you can successfully
          withdraw once a day.
        </div>
        <div class="tips-list">
          4: To activate the qualification for cash withdrawal, you need to
          purchase equipment by yourself, or first-level members can activate
          cash withdrawal by purchasing equipment.
        </div>
      </div>
      <div class="form-btn">
        <Button
          :disabled="showLoading"
          @click="onSubmit"
          round
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
        >
          Continue
        </Button>
      </div>
    </div>
    <ActionSheet
      v-model="showMethod"
      :actions="methodActions"
      @select="onSelect"
    />
    <Overlay :show="showLoading">
      <div class="Loadingwrapper">
        <Loading type="spinner" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import {
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  Field,
  Form,
  ActionSheet,
  Loading,
  Overlay,
  Toast,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    Field,
    Form,
    ActionSheet,
    Overlay,
    Loading,
  },
  props: {},
  data() {
    return {
      showLoading: false,
      showMethod: false,
      params: {
        // method: "",
        bank_name: "",
        ifsc_code: "",
        // new_password: "",
        actual_name: "",
        bank_account: "",
        // code: "",
      },
      methodActions: [
        { name: "Easypaisa" },
        { name: "Easypaisa 1" },
        { name: "Easypaisa 2" },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSelect(item) {
      console.log(item);
      this.params.method = item.name;
      this.showMethod = false;
    },
    onSubmit() {
      if (!this.params.actual_name) {
        Toast("Actual Name is required");
        return;
      }
      if (!this.params.actual_name) {
        Toast("Ifsc Code is required");
        return;
      }
      if (!this.params.actual_name) {
        Toast("Bank Name is required");
        return;
      }
      if (!this.params.actual_name) {
        Toast("Bank Account is required");
        return;
      }
      this.showLoading = true;
      let data = {
        actual_name: this.params.actual_name, // 所属人姓名
        ifsc_code: this.params.ifsc_code, //  银行编码
        bank_name: this.params.bank_name, //  银行名字
        bank_account: this.params.bank_account, // 银行卡号
        // v_code: '',// 验证码 先影藏吧。留后续备用
      };
      this.$axios({
        url: "/api/my/add_bank_card",
        method: "POST",
        data,
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          this.$router.back();
        }
        this.showLoading = false;
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
