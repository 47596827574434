import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
import { router } from "../src/router";

axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
// axios.defaults.headers['token'] = '';

// if (localStorage.getItem('userInfo')) {
//   axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('userInfo')).token;
// }

// 创建axios实例
const service = axios.create({
  //baseURL: 'https://art.33ej.cn', // 测试服
  //baseURL: 'https://art.asfgwsr.com',
  //baseURL: 'https://art.asfgwsr.in',
  // baseURL: 'https://art.williammall.net',
  baseURL: "https://art.allshop7.com",
  // headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //       // 'Content-Type': 'application/json; charset=utf-8'
  //   },
  timeout: 150000,
});

// request 请求拦截器
service.interceptors.request.use(
  (config) => {
    //    if(localStorage.getItem('userInfo')){
    // 	axios.defaults.headers = {
    // 	  "token": JSON.parse(localStorage.getItem('userInfo')).token,
    // 	  "Content-Type": 'application/x-www-form-urlencoded',
    // 	 }
    // }
    let token = localStorage.getItem("token") || "";
    config.headers = Object.assign(
      {},
      {
        "Content-Type": "application/json",
        token: token,
      },
      config.headers
    );
    config.data = qs.stringify(config.data);

    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// response 响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    // if(res.code == undefined){
    //     return res
    // }else if(res.code != 200) {
    //     console.log(res)
    //     // res = JSON.parse(res);
    //     Toast.fail(res.msg);
    // } else {
    if (response?.status == 200 && response?.data?.code == 1) {
      return response?.data;
    } else {
      Toast(response?.data?.msg || "error");
    }
    // }
  },
  (error) => {
    console.log(error, "error");
    let errRes = error?.response?.data || "";
    if (errRes?.code == 401) {
      router.push("/user/login");
    } else {
      Toast(error?.response?.data?.msg || "error");
      return Promise.reject(error);
    }
  }
);

export default service;
