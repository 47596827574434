<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">Recharge</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div
        class="wrapper-cell"
        style="
          background-image: url('/images/cell-bg.png');
          background-size: cover;
        "
      >
        <div class="cell-left">
          <div class="item-subtitle">My Balance</div>
          <div class="item-title">₹{{ moneyTotal }}</div>
        </div>
      </div>
      <div class="subtion">Payment</div>
      <div class="charge-wrapper-pay" @click="showMethod = true">
        {{ payInfo?.name ? payInfo.name : "Please Select" }}
      </div>
      <div class="subtion">Select or enter an amount(₹)</div>
      <div class="charge-wrapper-content">
        <div class="charge-wrapper">
          <div
            class="charge-item"
            :class="{ active: current == index }"
            v-for="(item, index) in cahrgeData"
            :key="index"
            @click="onItemClick(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
        <Field
          type="number"
          class="pay-number"
          v-model="payNumber"
          placeholder="Enter an amount(₹)"
        />
      </div>
      <div class="tips">
        <div class="tips-title">Instruction</div>
        <div class="tips-list">
          1. The minimum recharge is 500 rupees, and the recharge time is 7*24
          hours.
        </div>
        <div class="tips-list">
          2. If the application account balance does not reach within half an
          hour after the recharge is successful, please contact customer service
          in time and send us the account number and recharge success
          certificate.
        </div>
        <div class="tips-list">
          3. Please do not save the previous payment account number for
          transfer. The recharge receipt must match the payment amount. Avoid
          payment errors.
        </div>
        <div class="tips-list">
          4. Do not transfer money to strangers, recharge must be completed
          within the APP
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-left">
        ₹
        <span class="text">{{
          current == 10000 ? payNumber : cahrgeData[current].value
        }}</span>
      </div>
      <div class="footer-right">
        <Button
          :disabled="showLoading"
          @click="onSubmit"
          round
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
        >
          Recharge
        </Button>
      </div>
    </div>
    <ActionSheet
      v-model="showMethod"
      :actions="methodActions"
      @select="onSelect"
    />
    <Overlay :show="showLoading">
      <div class="Loadingwrapper">
        <Loading type="spinner" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import {
  Button,
  Icon,
  NavBar,
  Loading,
  Overlay,
  ActionSheet,
  Field,
  Toast,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Button,
    Icon,
    NavBar,
    Overlay,
    Loading,
    ActionSheet,
    Field,
  },
  data() {
    return {
      moneyTotal: "",
      payNumber: null,
      payInfo: {},
      showMethod: false,
      showLoading: false,
      current: 0,
      cahrgeData: [
        { name: "₹500", value: 500 },
        { name: "₹1000", value: 1000 },
        { name: "₹3000", value: 3000 },
        { name: "₹5000", value: 5000 },
        { name: "₹10000", value: 10000 },
        { name: "₹30000", value: 30000 },
        { name: "₹50000", value: 50000 },
        { name: "₹80000", value: 80000 },
        { name: "₹100000", value: 100000 },
      ],
      typeData: [],
      methodActions: [],
    };
  },
  mounted() {
    let moneyInfo = localStorage.getItem("moneyInfo");
    if (moneyInfo) {
      this.moneyTotal = JSON.parse(moneyInfo).money;
    }
    this.getType();
  },
  watch: {
    payNumber(newVal) {
      if (newVal) {
        this.current = 10000;
      } else {
        this.current = 0;
      }
    },
  },
  methods: {
    onSelect(item) {
      this.payInfo = item;
      this.showMethod = false;
    },
    getType() {
      // 获取充值方式
      this.$axios({
        url: "/api/project/getpaydatalist",
        method: "GET",
        data: {},
      }).then((res) => {
        if (res?.code == 1) {
          this.methodActions = res?.data?.list || [];
          this.methodActions.forEach((item) => {
            item.name = item.payname;
            item.value = item.port;
          });
        }
        console.log(res);
        // Payname：接口名字
        // Port：  接口编号  客户选择后提交到充值接口去的

        this.typeData = res.data.list;
      });
    },
    onItemClick(item, index) {
      this.current = index;
      this.payNumber = "";
    },
    onSubmit() {
      if (!this.payInfo?.name) {
        Toast("Payment is required");
        return;
      }
      this.showLoading = true;
      let data = {
        money:
          this.current == 10000
            ? this.payNumber
            : this.cahrgeData[this.current].value, // 金额
        type: this.payInfo.port, //选择的支付端口  （通道接口中的Port值）
        // 余额：在读接口就慢了。登录的时候就长存储。这里直接读取长存储的 localStorage
      };
      this.$axios({
        url: "/api/money/pay",
        method: "POST",
        data,
      }).then((res) => {
        if (res?.code == 1) {
          // this.$router.back();
          window.location.href = res?.data;
        } else {
          Toast(res?.msg);
        }
        this.showLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.pay-number {
  margin: 0 48px;
  width: calc(100% - 96px);
  border-radius: 5px;
  background: #0f2b85;
}
:deep(.van-field__control) {
  color: #fff;
}
/deep/ .van-cell::after {
  border-bottom: none;
}
</style>
