<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">Customer Service</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <img class="banner-img" src="/images/servie-banner.png" alt="" />
      <div class="grid-wrapper">
        <div class="grid-item" @click="goPage('telegram')">
          <img class="grid-img" src="/images/telegram-icon.png" alt="" />
          <div class="grid-text">Telegram</div>
        </div>
        <div class="grid-item" @click="goPage('whatsApp')">
          <img class="grid-img" src="/images/whats-app-icon.png" alt="" />
          <div class="grid-text text-green">Whats APP</div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="item-1">Customer service hours</div>
        <div class="item-2">Monday to Sunday 10:00 to 17:30</div>
        <div class="item-3">
          lf you have any questions, please contact customeservice during
          customer service business hoursthank you
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Grid,
  GridItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  Field,
  Form,
  ActionSheet,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Grid,
    GridItem,
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    Field,
    Form,
    ActionSheet,
  },
  props: {},
  data() {
    return {
      feiJiUrl: "",
      whatsAppUrl: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // 获取详情
      this.$axios({
        url: "/api/my/online",
        method: "GET",
        data: {},
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          this.feiJiUrl = res?.data?.Feijiqun || "";
          this.whatsAppUrl = res?.data?.onlineUrl || "";
        }
      });
    },
    goPage(type) {
      if (type == "telegram") {
        window.open(`${this.feiJiUrl}`);
      }
      if (type == "whatsApp") {
        window.open(`${this.whatsAppUrl}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
