<template>
    <div class="market-page" style="background-image: url('/images/project1-bg.png');background-size: cover">
        <div class="header">
            <NavBar :border="false" placeholder safe-area-inset-top>
                <template #left>
                    <Icon name="arrow-left" size="22"/>
                </template>
                <template #right>
                    <div class="right-btn">
                        <Icon name="/images/porse-icon.png" size="16"/><span class="span">Recharge</span>
                    </div>
                </template>
            </NavBar>
        </div>
        <div class="wrapper">
            <div class="page-title">Liebherr tunnel excavat..</div>
            <div class="project-wrapper">
                <div class="cell_group">
                    <div class="cell-item">
                        <div class="cell-title">
                            Invest amount:
                        </div>
                        <div class="cell-value">
                            Rs.20000
                        </div>
                    </div>
                    <div class="cell-item">
                        <div class="cell-title">
                            Hourly income:
                        </div>
                        <div class="cell-value">
                            Rs.1669.62
                        </div>
                    </div>
                    <div class="cell-item">
                        <div class="cell-title">
                            Daily income:
                        </div>
                        <div class="cell-value">
                            Rs.40000.00
                        </div>
                    </div>
                    <div class="cell-item">
                        <div class="cell-title">
                            Total income:
                        </div>
                        <div class="cell-value">
                            Rs.1410144444.62
                        </div>
                    </div>
                    <div class="cell-item">
                        <div class="cell-title">
                            Invest term:
                        </div>
                        <div class="cell-value">
                            360days
                        </div>
                    </div>
                    <div class="cell-item">
                        <div class="cell-title">
                            Investable quantity
                        </div>
                        <div class="cell-value">
                            0/15
                        </div>
                    </div>
                </div>
                <div class="alert_wrapper">
                    <div class="alert-item">
                        nvest in mining machines to obtain equipment incomeAfter
                        successful investment, mining machine income isautomatically
                        generated every hour.
                    </div>
                    <div class="alert-item">
                        The device has not yet been put into useso look forward to it
                    </div>
                </div>
                <div class="btn-wrapper">
                    <div class="btn-1">
                        <Button round style="background-image: url('/images/w-600.png');background-size: cover">
                            Investable quantity: 0/0
                        </Button>
                    </div>
                    <div class="btn-2">
                        <Button round>
                            Cancel
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon,NavBar,Button} from 'vant';
export default {
    name: 'homeProject',
    components: {
        Icon,NavBar,Button
    },
    props: {},
    data() {
        return {

        }
    },
    watch: {},
    computed: {},
    methods: {
        onTabClick(item,index){
            this.tabActive=index
        }

    },
    created() {},
    mounted() {}
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
