<template>
  <div
    class="container"
    style="
      background-image: url('/images/login-bg.png');
      background-size: cover;
    "
  >
    <div class="wrapper">
      <div class="txt-welcome">
        <img src="/images/logo.png" alt="" class="img-logo" />
      </div>
      <Form>
        <div class="form-tab">
          <div
            class="form-tab-log"
            style="
              background-image: url('/images/tixing-icon.png');
              background-size: cover;
            "
          >
            log in
          </div>
          <div
            class="form-tab-reg"
            style="
              background-image: url('/images/reg-icon.png');
              background-size: cover;
            "
            @click="$router.push('/user/register')"
          >
            Register
          </div>
        </div>
        <div class="form-item">
          <div class="label">Phone</div>
          <Field
            class="txt-field"
            v-model="params.phone"
            placeholder="Please input phone"
          >
            <template #left-icon>
              <div class="left-icon">91+</div>
            </template>
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Password</div>
          <Field
            class="txt-field"
            v-model="params.password"
            placeholder="Please Input Password"
            type="password"
          >
          </Field>
        </div>
      </Form>
      <div class="form-btn">
        <Button
          round
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
          @click="login_sub()"
        >
          Sign IN
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Field, Checkbox, Button, Form, Toast } from "vant";
export default {
  name: "AccountLogin",
  components: {
    NavBar,
    Field,
    Checkbox,
    Button,
    Form,
  },
  props: {},
  data() {
    return {
      params: {
        phone: "",
        password: "",
      },
      checked: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //登录
    login_sub() {
      var _this = this;
      console.log(_this.params.phone, _this.params.password);
      if (!_this.params.phone) {
        Toast("Mobile number is required");
      } else if (!_this.params.password) {
        Toast("Password is required");
      } else {
        _this.show_loading = true;

        _this
          .$axios({
            url: "/api/user/login",
            methods: "get",
            params: {
              account: _this.params.phone,
              password: _this.params.password,
            },
          })
          .then((res) => {
            //成功操作
            if (res.code == 1) {
              localStorage.setItem(
                "userInfo",
                JSON.stringify(res.data.userinfo)
              );
              localStorage.setItem("token", res?.data?.userinfo?.token);
              localStorage.setItem("isShow", 1);
              // if (localStorage.getItem("hbcode")) {
              //   //红包链接
              //   _this.$router.push("/redenvelopes"); //红包页面
              // } else {
              //   _this.$router.push("/mine");
              // }
              _this.$router.push("/");
            } else {
              Toast(res.msg);
            }

            //loading
            _this.show_loading = false;
          })
          .catch((res) => {
            // 失败进行的操作
            Toast("Network Abnormal");
            console.log(res);
            _this.show_loading = false;
          });
      }
    },
  },
  mounted() {
    console.log(this.$api);
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
