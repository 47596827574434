<template>
  <div
    class="container"
    style="
      background-image: url('/images/login-bg.png');
      background-size: cover;
    "
  >
    <div class="wrapper">
      <div class="txt-welcome">
        <img src="/images/logo.png" alt="" class="img-logo" />
      </div>
      <Form>
        <div class="form-tab">
          <div
            class="form-tab-reg"
            style="
              background-image: url('/images/reg-icon.png');
              background-size: cover;
            "
            @click="$router.push('/user/login')"
          >
            log in
          </div>
          <div
            class="form-tab-log"
            style="
              background-image: url('/images/tixing-icon.png');
              background-size: cover;
            "
          >
            Register
          </div>
        </div>
        <div class="form-item">
          <div class="label">Phone</div>
          <Field
            class="txt-field"
            v-model="params.phone"
            placeholder="Please input phone"
          >
            <template #left-icon>
              <div class="left-icon">91+</div>
            </template>
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Verification Code</div>
          <Field
            class="txt-field"
            v-model="params.code"
            placeholder="Please Input verification code"
          >
            <template #right-icon>
              <div class="right-icon-btn">
                <Button
                  @click="getCode"
                  style="
                    background-image: url('/images/btn-bg1.png');
                    background-size: cover;
                  "
                >
                  <span v-if="!isStart"> Get </span>
                  <span v-else>{{ numCount }}</span>
                </Button>
              </div>
            </template>
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Password</div>
          <Field
            class="txt-field"
            v-model="params.password"
            placeholder="Please Input Password"
            type="password"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Confirm Password</div>
          <Field
            class="txt-field"
            v-model="params.conf_password"
            placeholder="Please Input Confirm Password"
            type="password"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Referrer</div>
          <Field
            :readonly='!!referrer'
            class="txt-field"
            v-model="params.referrer"
            placeholder="Please Input Referrer"
          >
          </Field>
        </div>
      </Form>
      <div class="form-btn">
        <Button
          round
          @click="onSubmit"
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
        >
          Continue
        </Button>
        <div class="tips">
          Existing Account ,
          <span class="span" @click="$router.push('/user/login')">Login?</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Field, Checkbox, Button, Form, Toast } from "vant";
export default {
  name: "AccountLogin",
  components: {
    NavBar,
    Field,
    Checkbox,
    Button,
    Form,
  },
  data() {
    return {
      params: {
        phone: "",
        code: "",
        password: "",
        conf_password: "",
        referrer: "",
      },
      referrer:'',
      numCount: 180,
      isStart: false,
      timer: null,
      checked: false,
    };
  },
  watch: {
    numCount(value) {
      if (value == 0) {
        clearInterval(this.timer);
        this.isStart = false;
        this.numCount = 180;
      }
    },
  },
  computed: {
  },
  mounted() {
  if(localStorage.getItem('userInfo')){ //是否登录 登录跳转首页 没登录跳转注册页面
				  this.$router.push('/');
			  }else{
				  var r_code = window.location.href.split('=')[1];
				  this.referrer = r_code;
          this.params.referrer = r_code;
			   }
    console.log(this.referrer,"11111111111");
  },
  methods: {
    getCode() {
      if (!this.params.phone) {
        Toast("Mobile number is required");
        return;
      }
      if (this.isStart) return;
      this.$axios({
        url: "/api/sms/ali_send",
        method: "POST",
        data: {
          mobile: this.params.phone,
        },
      }).then((res) => {
        if (res?.code == 1) {
          this.timer = setInterval(() => {
            this.numCount--;
            this.isStart = true;
          }, 1000);
        }
      });
    },
    onSubmit() {
      if (!this.params.phone) {
        Toast("Mobile number is required");
        return;
      }
      if (!this.params.code) {
        Toast("Verification code is required");
        return;
      }
      if (!this.params.password) {
        Toast("Password is required");
        return;
      }
      if (!this.params.conf_password) {
        Toast("Confirm Password is required");
        return;
      }
      if (this.params.conf_password != this.params.password) {
        Toast("Password and Confirm Password are different");
        return;
      }
      this.$axios({
        url: "/api/user/register",
        method: "POST",
        data: {
          mobile: this.params.phone,
          v_code: this.params.code,
          password: this.params.password,
          p_code: this.params.referrer,
        },
      }).then((res) => {
        if (res?.code == 1) {
          Toast("Sucess");
          this.$router.replace("/user/register");
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./register.less";
</style>
