<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">Login password</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <Form>
        <div class="form-item">
          <div class="label">Original Password</div>
          <Field
            class="txt-field"
            v-model="params.original_password"
            placeholder="Please Input Original Password"
            type="password"
            autocomplete="current-password"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">New password</div>
          <Field
            class="txt-field"
            v-model="params.new_password"
            placeholder="Please Input New Password"
            type="password"
            autocomplete="current-password"
          >
          </Field>
        </div>
        <div class="form-item">
          <div class="label">Confirm New password</div>
          <Field
            class="txt-field"
            v-model="params.conf_password"
            placeholder="Please Input Confirm Password"
            type="password"
            autocomplete="current-password"
          >
          </Field>
        </div>
      </Form>
      <div class="form-btn">
        <Button
          :disabled="showLoading"
          @click="onSubmit"
          round
          style="
            background-image: url('/images/w-600.png');
            background-size: cover;
          "
        >
          Continue
        </Button>
      </div>
    </div>
    <!-- <ActionSheet
      v-model="showMethod"
      :actions="methodActions"
      @select="onSelect"
    /> -->
    <Overlay :show="showLoading">
      <div class="Loadingwrapper">
        <Loading type="spinner" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import {
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  Field,
  Form,
  Loading,
  Overlay,
  Toast,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    Field,
    Form,
    Loading,
    Overlay,
    Toast,
  },
  props: {},
  data() {
    return {
      showLoading: false,
      params: {
        original_password: "",
        new_password: "",
        conf_password: "",
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      if (!this.params.original_password) {
        Toast("Original Password is required");
        return;
      }
      if (!this.params.new_password) {
        Toast("New password is required");
        return;
      }
      if (!this.params.conf_password) {
        Toast("Confirm Password is required");
        return;
      }
      if (this.params.conf_password != this.params.new_password) {
        Toast("New password and Confirm New password are different");
        return;
      }
      this.showLoading = true;
      let data = {
        opw: this.params.original_password,
        npw: this.params.new_password,
        npw2: this.params.conf_password,
      };
      this.$axios({
        url: "/api/my/resetpwd",
        method: "POST",
        data,
      }).then((res) => {
        console.log(res);
        this.showLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
