<template>
  <div class="market-page">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar :border="false" placeholder safe-area-inset-top>
        <template #left>
          <div class="nav-title">Team</div>
        </template>
        <template #right>
          <div class="navbar-right" @click="goPage">
            <div class="icon-label">Online Service</div>
            <Icon name="/images/kefu-icon.png" size="20" />
          </div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div
        class="wrapper-cell"
        style="
          background-image: url('/images/team-cell-bg.png');
          background-size: cover;
        "
      >
        <div class="cell-share">
          <Icon name="/images/share-icon.png" size="18" />
          <div class="icon-label">My invite code</div>
        </div>
        <div
          class="cell-copy"
          style="
            background-image: url('/images/copy-bg.png');
            background-size: cover;
          "
        >
          <div class="icon-label">{{ code }}</div>
          <Icon
            name="/images/copy-icon.png"
            @click="onCopyText(code,'code')"
            size="14"
          />
        </div>
        <div class="cell-copy-url">
          {{ realmname }}
          <img
            class="fuzhi-icon"
            @click="onCopyText(realmname,'')"
            src="/images/fuzhi-icon.png"
            alt=""
          />
        </div>
      </div>
      <div class="project-wrapper">
        <div class="project-lsit">
          <CellGroup :border="false" center>
            <Cell :border="false">
              <template #icon>
                <Icon name="/images/no1.png" size="36" />
              </template>
              <template #title>
                <div class="title">My first-level team</div>
              </template>
              <template #right-icon>
                <div class="value">Commission:{{ teamInfo?.oneratio }}%</div>
              </template>
            </Cell>
            <div class="grid">
              <Grid :column-num="3" :border="false">
                <GridItem>
                  <div class="grid-title">{{ teamInfo?.onesum ?? 0 }}</div>
                  <div class="grid-value">Total people</div>
                </GridItem>
                <GridItem>
                  <div class="grid-title">
                    <span class="span">Rs.</span>{{ teamInfo?.onemoney ?? 0 }}
                  </div>
                  <div class="grid-value">Total invest</div>
                </GridItem>
                <GridItem icon="photo-o" text="文字">
                  <div class="grid-title">
                    <span class="span">Rs.</span
                    >{{ teamInfo?.onecommission ?? 0 }}
                  </div>
                  <div class="grid-value">Total commission</div>
                </GridItem>
              </Grid>
            </div>
          </CellGroup>
        </div>
        <div class="project-lsit">
          <CellGroup :border="false" center>
            <Cell :border="false">
              <template #icon>
                <Icon name="/images/no2.png" size="36" />
              </template>
              <template #title>
                <div class="title">My second-level team</div>
              </template>
              <template #right-icon>
                <div class="value">Commission:{{ teamInfo?.tworatio }}%</div>
              </template>
            </Cell>
            <div class="grid">
              <Grid :column-num="3" :border="false">
                <GridItem>
                  <div class="grid-title">{{ teamInfo?.twosum ?? 0 }}</div>
                  <div class="grid-value">Total people</div>
                </GridItem>
                <GridItem>
                  <div class="grid-title">
                    <span class="span">Rs.</span>{{ teamInfo?.twomoney ?? 0 }}
                  </div>
                  <div class="grid-value">Total invest</div>
                </GridItem>
                <GridItem icon="photo-o" text="文字">
                  <div class="grid-title">
                    <span class="span">Rs.</span
                    >{{ teamInfo?.twocommission ?? 0 }}
                  </div>
                  <div class="grid-value">Total commission</div>
                </GridItem>
              </Grid>
            </div>
          </CellGroup>
        </div>
        <div class="project-lsit">
          <CellGroup :border="false" center>
            <Cell :border="false">
              <template #icon>
                <Icon name="/images/no3.png" size="36" />
              </template>
              <template #title>
                <div class="title">My third-level team</div>
              </template>
              <template #right-icon>
                <div class="value">Commission:{{ teamInfo?.threeratio }}%</div>
              </template>
            </Cell>
            <div class="grid">
              <Grid :column-num="3" :border="false">
                <GridItem>
                  <div class="grid-title">{{ teamInfo?.threesum ?? 0 }}</div>
                  <div class="grid-value">Total people</div>
                </GridItem>
                <GridItem>
                  <div class="grid-title">
                    <span class="span">Rs.</span>{{ teamInfo?.threemoney ?? 0 }}
                  </div>
                  <div class="grid-value">Total invest</div>
                </GridItem>
                <GridItem icon="photo-o" text="文字">
                  <div class="grid-title">
                    <span class="span">Rs.</span
                    >{{ teamInfo?.threecommission ?? 0 }}
                  </div>
                  <div class="grid-value">Total commission</div>
                </GridItem>
              </Grid>
            </div>
          </CellGroup>
        </div>
      </div>
      <!-- <div class="subtion">
        <Cell :border="false">
          <template #icon>
            <Icon name="/images/share-icon.png" size="26" />
          </template>
          <template #title>
            <div class="title">Invite friends to register rewards</div>
          </template>
        </Cell>
      </div>
      <div class="project-wrapper">
        <div class="project-lsit">
          <CellGroup :border="false" center>
            <Cell :border="false">
              <template #icon>
                <Icon name="/images/wallet.png" size="30" />
              </template>
              <template #title>
                <div class="Invite-title">Invite friends to register bonus</div>
              </template>
              <template #right-icon>
                <div class="Invite-value">
                  Rs: {{ teamInfo?.tzratio || "" }}%
                </div>
              </template>
            </Cell>
            <div class="process">
              <div class="process-title">Progress rate</div>
              <Progress
                :percentage="50"
                stroke-width="10px"
                pivot-text=" "
                color="linear-gradient(to right,#184DC0, #11B1EF)"
              >
              </Progress>
            </div>
            <div class="grid">
              <Grid :column-num="3" :border="false">
                <GridItem style="flex-basis: 40%">
                  <div class="Invite-grid-title">0.00</div>
                  <div class="Invite-grid-value">Cumulatively obtained</div>
                </GridItem>
                <GridItem style="flex-basis: 30%">
                  <div class="Invite-grid-title">0.00</div>
                  <div class="Invite-grid-value">Available</div>
                </GridItem>
                <GridItem style="flex-basis: 30%">
                  <div class="grid-btn">
                    <Button
                      round
                      style="
                        background-image: url('/images/buttom-bg.png');
                        background-size: cover;
                      "
                    >
                      Receive
                    </Button>
                  </div>
                </GridItem>
              </Grid>
            </div>
          </CellGroup>
        </div>
      </div> -->
      <div class="subtion">
        <Cell :border="false">
          <template #icon>
            <Icon name="/images/share-icon.png" size="26" />
          </template>
          <template #title>
            <div class="title">Invite friends to register rewards</div>
          </template>
          <template #right-icon>
            <div class="value">Rebate record</div>
          </template>
        </Cell>
      </div>
      <div class="project-wrapper">
        <div class="project-lsit">
          <CellGroup :border="false" center>
            <Cell :border="false">
              <template #icon>
                <Icon name="/images/wallet.png" size="30" />
              </template>
              <template #title>
                <div class="Invite-title">
                  Invite friends to invest device for the first time and get
                  <span class="span">{{ teamInfo?.tzratio ?? "" }}%</span>
                  rebate
                </div>
              </template>
            </Cell>
            <div class="grid">
              <Grid :column-num="3" :border="false">
                <GridItem style="flex-basis: 40%">
                  <div class="Invite-grid-title">
                    {{ teamInfo?.tzmoney ?? "" }}
                  </div>
                  <div class="Invite-grid-value">Cumulatively obtained</div>
                </GridItem>
                <GridItem style="flex-basis: 30%">
                  <div class="Invite-grid-title">
                    {{ teamInfo?.tzcount ?? "" }}
                  </div>
                  <div class="Invite-grid-value">Available</div>
                </GridItem>
                <GridItem style="flex-basis: 30%">
                  <div class="grid-btn">
                    <Button
                      round
                      style="
                        background-image: url('/images/buttom-bg.png');
                        background-size: cover;
                      "
                    >
                      Receive
                    </Button>
                  </div>
                </GridItem>
              </Grid>
            </div>
          </CellGroup>
        </div>
      </div>
      <div class="container">
        <div class="container__title">Why create a team?</div>
        <div class="container__content">
          If you want to make more money, you can invite your friends to
          register and join us, and you can get 15% first investment rebate. And
          you can get 10% 2%1% commission from the long-term equipment profits
          of team members
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Progress,
  Grid,
  GridItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Search,
  DropdownMenu,
  DropdownItem,
} from "vant";
import { copyText } from "@/utils/common";
export default {
  name: "homeProject",
  components: {
    Progress,
    Grid,
    GridItem,
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Search,
    DropdownMenu,
    DropdownItem,
  },
  props: {},
  data() {
    return {
      code: "",
      realmname: "",
      teamInfo: {},
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.getTramList();
  },
  methods: {
    goPage() {
      this.$router.push("/user/customerService");
    },
    onCopyText(text,type) {
      console.log(window.location.href);
      if(type == 'code') {
        let origin = window.location.origin;
        let copyTextDesc = `${origin}/#/user/register?r_code=${text}`
        copyText(copyTextDesc);
      }else {
        copyText(text);
      }
    },
    getTramList() {
      this.$axios({
        url: "/api/my/my_promotion",
        method: "POST",
      }).then((res) => {
        if (res?.code == 1) {
          console.log(res);
          this.code = res?.data?.code || "";
          this.realmname = res?.data?.realmname || "";
          this.teamInfo = res?.data || {};
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
