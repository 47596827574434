<template>
  <div class="index-page" style="background-image: url('/images/bg_home.png')">
    <div class="header">
      <NavBar :border="false" placeholder safe-area-inset-top>
        <template #left>
          <img src="/images/logo.png" alt="" class="img-logo" />
        </template>
        <template #right>
          <div class="navbar-right" @click="goPage">
            <div class="icon-label">Online Service</div>
            <Icon name="/images/kefu-icon.png" size="20" />
          </div>
        </template>
      </NavBar>
    </div>
    <div class="index-content">
      <div class="banner">
        <Swipe autoplay='3000'>
          <SwipeItem >
            <img class="banner-img" src="/images/mock/banner1.png" />
          </SwipeItem>
          <SwipeItem>
            <img class="banner-img" src="/images/mock/banner2.png" />
          </SwipeItem>
          <SwipeItem>
            <img class="banner-img" src="/images/mock/banner3.png" />
          </SwipeItem>
          <SwipeItem>
            <img class="banner-img" src="/images/mock/banner4.png" />
          </SwipeItem>
          <SwipeItem>
            <img class="banner-img" src="/images/mock/banner5.png" />
          </SwipeItem>
          <SwipeItem>
            <img class="banner-img" src="/images/mock/Chanpin1.png" />
          </SwipeItem>
          <SwipeItem>
            <img class="banner-img" src="/images/mock/Chanpin2.png" />
          </SwipeItem>
        </Swipe>
      </div>

      <div class="news-notice">
        <NoticeBar :scrollable="false">
          <template #left-icon>
            <Icon name="/images/notice-icon.png" size="38" />
          </template>
          <template #default>
            <Swipe
              vertical
              class="notice-swipe"
              :autoplay="3000"
              :touchable="false"
              :show-indicators="false"
            >
              <SwipeItem
                v-for="(item, index) in noticeList"
                :key="index"
                class="notice-swipe-item"
                >{{ item.username }} ： {{ item.money }}</SwipeItem
              >
            </Swipe>
          </template>
        </NoticeBar>
      </div>
      <div class="grid-wrapper">
        <Grid column-num="2" :center="false">
          <GridItem>
            <div class="grid-item">
              <img class="grid-item-img" src="/images/wallet.png" />
              <div class="grid-item-text">My balance</div>
            </div>
            <div class="title">Rs.{{ money }}</div>
          </GridItem>
          <GridItem>
            <div class="grid-item">
              <img class="grid-item-img" src="/images/wallet.png" />
              <div class="grid-item-text">Total income</div>
            </div>
            <div class="title">Rs.{{ myIncome }}</div>
          </GridItem>
        </Grid>
      </div>
      <div class="adv-wrapper" @click="goTram">
        <img class="adv-img" src="/images/adv.png" />
      </div>
      <div class="project-wrapper">
        <div class="subtion">Project</div>
        <div class="project-list">
          <div
            class="lists"
            v-for="(item, index) in orderList"
            :key="index"
            @click="goDetail(item)"
          >
            <div class="list-tip" v-if="item.state == 2">
              <div class="list-tip-desc">Vote</div>
              <img src="../../../../public/images/bg-blue.png" />
            </div>
            <div class="list-tip" v-if="item.state == 3">
              <div class="list-tip-desc">Not open yet</div>
              <img
                style="width: 75%"
                src="../../../../public/images/bg-orange.png"
              />
            </div>
            <img class="items-img" :src="baseUrl + item.img" />
            <div class="fixed-content">
              <div class="title">{{ item?.title }}</div>
              <div class="sub-title">
                <div class="sub-title-row left">Hourly income</div>
                <div class="sub-title-row right">Rs.{{ item.rate }}</div>
              </div>
              <div class="sub-title">
                <div class="sub-title-row left">Daily income</div>
                <div class="sub-title-row right">Rs.{{ item.dailyIncome }}</div>
              </div>
              <div class="sub-title">
                <div class="sub-title-row left">Invest term</div>
                <div class="sub-title-row right">{{ item.day }}</div>
              </div>
              <div class="sub-title">
                <div class="sub-title-row left">Invest amount</div>
                <div class="sub-title-row right">Rs.{{ item.min }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Popup v-model="show" round>
      <div class="popup-container">
        <div class="title">
          <img class="title-icon" src="/images/title-icon.png" alt="" />
        </div>
        <div class="popup-body">
          <div v-html="notice" class="content"></div>
          <div class="tips">
            The platfm was officiallylaunched on September 18th iscurrently
            undergoing bidding
          </div>
          <div class="btn">
            <Button
              round
              style="
                background-image: url('/images/buttom-bg.png');
                background-size: cover;
              "
              @click="hide_tz()"
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import {
  Icon,
  NavBar,
  NoticeBar,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Popup,
  Button,
} from "vant";
import config from "../../../../request/config";
export default {
  name: "homeIndex",
  components: {
    Icon,
    NavBar,
    NoticeBar,
    Swipe,
    SwipeItem,
    Grid,
    GridItem,
    Popup,
    Button,
  },
  data() {
    return {
      searchValue: "",
      show: true,
      notice: "",
      noticeBar: "",
      orderList: [],
      baseUrl: "",
      noticeList: [],
      money: "",
      myIncome: "",
    };
  },
  mounted() {
    this.baseUrl = config.baseURL;
    this.getOrderList();
    this.getNotice();
    this.getNoticeBar();
    this.getMoney();
  },
  methods: {
    //关闭通知
    hide_tz() {
      this.show = false;
    },
    // 获取收益
    getMoney() {
      this.$axios({
        url: "/api/my/index",
        method: "POST",
      }).then((res) => {
        if (res?.code == 1) {
          this.money = res?.data?.money ?? "";
          this.myIncome = res?.data?.my_income ?? "";
        }
      });
    },
    // 获取列表
    getOrderList() {
      this.$axios({
        url: "/api/project/itemlist",
        method: "POST",
      }).then((res) => {
        if (res?.code == 1) {
          this.orderList = res?.data?.list || [];
          this.orderList.forEach((item) => {
            item.dailyIncome = Number(item.rate * 12).toFixed(2);
          });
        }
      });
    },
    // 获取弹框公告
    getNotice() {
      this.$axios({
        url: "/api/my/notice",
        method: "POST",
      }).then((res) => {
        if (res?.code == 1) {
          this.notice = res?.data || "";
        }
      });
    },
    getNoticeBar() {
      this.$axios({
        url: "/api/my/noticelist",
        method: "POST",
      }).then((res) => {
        if (res?.code == 1) {
          this.noticeList = res?.data?.list || [];
          // this.notice = res?.data || "";
        }
      });
    },
    goDetail(info) {
      if (info.state == 1 || info.state == 2) {
        this.$router.push(`/project/project1?orderId=${info.id}`);
      }
    },
    goTram() {
      this.$router.push("/tram");
    },
    goPage() {
      this.$router.push("/user/customerService");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.notice-swipe {
  height: 200px;
  line-height: 200px;
  width: 500px;
}
</style>
