import VueRouter from "vue-router";
import Vue from "vue";
import UserLogin from "@/pages/user/login";
import UserRegister from "@/pages/user/register";
import HomePage from "@/pages/homePage.vue";
import HomeIndexPage from "@/pages/home/index";
import HomeProjectPage from "@/pages/home/project";
import HomeTaskPage from "@/pages/home/task";
import HomeTramPage from "@/pages/home/tram";
import HomeUserPage from "@/pages/home/user";
import billPage from "@/pages/user/bill";
import userPasswordPage from "@/pages/user/password";
import backAccountPage from "@/pages/user/backAccount";
import withdrawalPage from "@/pages/user/withdrawal";
import customerServicePage from "@/pages/user/customerService";
import rechargePage from "@/pages/user/recharge";
import signInPage from "@/pages/user/signIn";
import ProjectIncomePage from "@/pages/project/income";
import Project1Page from "@/pages/project/project1";
import Project2Page from "@/pages/project/project2";
import Project3Page from "@/pages/project/project3";
import Article from "@/pages/user/article";
import ArticleAbout from "@/pages/user/articleAbout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomePage,
    children: [
      {
        path: "/",
        component: HomeIndexPage,
        meta: {
          isLogin: true, // 是否需要判断是否登录,这里是需要判断
        },
      },
      {
        path: "/project",
        component: HomeProjectPage,
        meta: {
          isLogin: true, // 是否需要判断是否登录,这里是需要判断
        },
      },
      {
        path: "/task",
        component: HomeTaskPage,
        meta: {
          isLogin: true, // 是否需要判断是否登录,这里是需要判断
        },
      },
      {
        path: "/tram",
        component: HomeTramPage,
        meta: {
          isLogin: true, // 是否需要判断是否登录,这里是需要判断
        },
      },
      {
        path: "/user",
        component: HomeUserPage,
        meta: {
          isLogin: true, // 是否需要判断是否登录,这里是需要判断
        },
      },
    ],
  },
  {
    path: "/user/bill",
    component: billPage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/password",
    component: userPasswordPage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/backAccount",
    component: backAccountPage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/withdrawal",
    component: withdrawalPage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/customerService",
    component: customerServicePage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/recharge",
    component: rechargePage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/signIn",
    component: signInPage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/project/income",
    component: ProjectIncomePage,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/project/Project1",
    component: Project1Page,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/project/Project2",
    component: Project2Page,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/project/Project3",
    component: Project3Page,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/article",
    component: Article,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/articleAbout",
    component: ArticleAbout,
    meta: {
      isLogin: true, // 是否需要判断是否登录,这里是需要判断
    },
  },
  {
    path: "/user/login",
    component: UserLogin,
  },
  {
    path: "/user/register",
    component: UserRegister,
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
});

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

// 校验用户手动输入页面时，是否登录
router.beforeEach((to, from, next) => {
  // 判断该路由是否需要登录权限
  if (to.meta?.isLogin) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next("/user/login");
    }
  } else {
    next(); // 确保一定要有next()被调用
  }
});

export { router };
