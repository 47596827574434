import Vue from "vue";
import App from "./App.vue";
import "vant/lib/index.less";
import "./assets/style/reset.css";
import "./assets/style/style.less";
import { router } from "./router";
import axios from "../request/http.js";
import api from "../request/api.js";
import {
  Toast,
  Button,
  Icon,
  NavBar,
  Overlay,
  Loading,
  ActionSheet,
  Field,
} from "vant";

Vue.config.productionTip = false;
import "@/assets/fonts/HanYiLiLiangHeiJian.ttf";
import "@/assets/fonts/PingFang Bold.ttf";
import "@/assets/fonts/PingFang Light.ttf";
import "@/assets/fonts/PingFang Medium.ttf";
import "@/assets/fonts/PingFang Regular.ttf";
import "@/assets/fonts/Radikal Medium.otf";
import "@/assets/fonts/DIN-Black.otf";
import "@/assets/fonts/DIN-Bold.otf";
import "@/assets/fonts/DIN-Light.otf";
import "@/assets/fonts/DIN-Medium.otf";

Vue.use(Toast);
Vue.use(Button);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(ActionSheet);
Vue.use(Field);
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
