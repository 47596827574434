<template>
    <div class="market-page">
        <div class="header" style="background-image: url('/images/blue-bg.png');background-size: cover">
            <NavBar :border="false" placeholder safe-area-inset-top>
                <template #left>
                    <div class="nav-title">Task</div>
                </template>
                <template #right>
                    <div class="navbar-right">
                        <div class="icon-label">Online Service</div>
                        <Icon name="/images/kefu-icon.png" size="20" />
                    </div>
                </template>
            </NavBar>
        </div>
        <div class="wrapper">
            <div class="wrapper-cell" style="background-image: url('/images/cell-2-bg.png');background-size: cover">
                <CellGroup :border="false" center>
                    <Cell :border="false">
                        <template #icon>
                            <div class="cell-icon">
                                <div class="item-title">Rs.40000.00</div>
                                <div class="item-subtitle">My device</div>
                            </div>
                        </template>
                        <template #title>
                            <div class="cell-title">
                                <div class="item-title">Rs.40000.00</div>
                                <div class="item-subtitle">Device income</div>
                            </div>
                        </template>
                        <template #right-icon>
                            <Icon name="arrow" size="20" />
                        </template>
                    </Cell>
                    <Cell :border="false">
                        <template #icon>
                            <div class="cell-icon">
                                <div class="item-title">Rs.40000.00</div>
                                <div class="item-subtitle">My device</div>
                            </div>
                        </template>
                        <template #title>
                            <div class="cell-title">
                                <div class="item-title">Rs.40000.00</div>
                                <div class="item-subtitle">Device income</div>
                            </div>
                        </template>
                        <template #right-icon>
                            <Icon name="arrow" size="20" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
            <div class="subtion">
                <Cell :border="false">
                    <template #icon>
                        <Icon name="/images/project-icon.png" size="34" />
                    </template>
                    <template #title>
                        <div class="title">
                            My project
                        </div>
                    </template>
                    <template #right-icon>
                        <div class="value">
                            Record
                        </div>
                    </template>
                </Cell>

            </div>
            <div class="project-wrapper">
                <div class="project-lsit">
                    <CellGroup :border="false" center>
                        <Cell :border="false">
                            <template #icon>
                                <div class="image">
                                    <img class="task-img" src="/images/task.png" />
                                </div>
                            </template>
                            <template #title>
                                <div class="title">
                                    Liebherr tunnel excavat..
                                </div>
                            </template>
                            <template #label>
                                <div class="label">
                                    Purchase limit
                                </div>
                                <div class="label-number">
                                    0/1
                                </div>
                            </template>
                            <template #right-icon>
                                <Button round style="background-image: url('/images/buttom-bg.png');background-size: cover">
                                    Purchase
                                </Button>
                            </template>
                        </Cell>
                        <Cell :border="false">
                            <template #title>
                               <div class="cell-title">
                                   <div class="cell-list">
                                       <div class="item-left">Price:</div>
                                       <div class="item-right orange">Rs.688</div>
                                   </div>
                                   <div class="cell-list">
                                       <div class="item-left">Cycle:</div>
                                       <div class="item-right">3day</div>
                                   </div>
                                   <div class="cell-list">
                                       <div class="item-left">Daily income:</div>
                                       <div class="item-right">Rs.100</div>
                                   </div>
                                   <div class="cell-list">
                                       <div class="item-left">Total income:</div>
                                       <div class="item-right">Rs.300</div>
                                   </div>
                               </div>
                            </template>
                        </Cell>
                    </CellGroup>
                </div>
                <div class="project-lsit">
                    <CellGroup :border="false" center>
                        <Cell :border="false">
                            <template #icon>
                                <div class="image">
                                    <img class="task-img" src="/images/task.png" />
                                </div>
                            </template>
                            <template #title>
                                <div class="title">
                                    Liebherr tunnel excavat..
                                </div>
                            </template>
                            <template #label>
                                <div class="label">
                                    Purchase limit
                                </div>
                                <div class="label-number">
                                    0/1
                                </div>
                            </template>
                            <template #right-icon>
                                <Button round style="background-image: url('/images/buttom-bg.png');background-size: cover">
                                    Purchase
                                </Button>
                            </template>
                        </Cell>
                        <Cell :border="false">
                            <template #title>
                                <div class="cell-title">
                                    <div class="cell-list">
                                        <div class="item-left">Price:</div>
                                        <div class="item-right orange">Rs.688</div>
                                    </div>
                                    <div class="cell-list">
                                        <div class="item-left">Cycle:</div>
                                        <div class="item-right">3day</div>
                                    </div>
                                    <div class="cell-list">
                                        <div class="item-left">Daily income:</div>
                                        <div class="item-right">Rs.100</div>
                                    </div>
                                    <div class="cell-list">
                                        <div class="item-left">Total income:</div>
                                        <div class="item-right">Rs.300</div>
                                    </div>
                                </div>
                            </template>
                        </Cell>
                    </CellGroup>
                </div>
                <div class="project-lsit">
                    <CellGroup :border="false" center>
                        <Cell :border="false">
                            <template #icon>
                                <div class="image">
                                    <img class="task-img" src="/images/task.png" />
                                </div>
                            </template>
                            <template #title>
                                <div class="title">
                                    Liebherr tunnel excavat..
                                </div>
                            </template>
                            <template #label>
                                <div class="label">
                                    Purchase limit
                                </div>
                                <div class="label-number">
                                    0/1
                                </div>
                            </template>
                            <template #right-icon>
                                <Button round style="background-image: url('/images/buttom-bg.png');background-size: cover">
                                    Purchase
                                </Button>
                            </template>
                        </Cell>
                        <Cell :border="false">
                            <template #title>
                                <div class="cell-title">
                                    <div class="cell-list">
                                        <div class="item-left">Price:</div>
                                        <div class="item-right orange">Rs.688</div>
                                    </div>
                                    <div class="cell-list">
                                        <div class="item-left">Cycle:</div>
                                        <div class="item-right">3day</div>
                                    </div>
                                    <div class="cell-list">
                                        <div class="item-left">Daily income:</div>
                                        <div class="item-right">Rs.100</div>
                                    </div>
                                    <div class="cell-list">
                                        <div class="item-left">Total income:</div>
                                        <div class="item-right">Rs.300</div>
                                    </div>
                                </div>
                            </template>
                        </Cell>
                    </CellGroup>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Button,Tab, Tabs,Cell, CellGroup,Icon,NavBar,Search, DropdownMenu, DropdownItem } from 'vant';
export default {
    name: 'homeProject',
    components: {
        Button,Tab, Tabs,Cell, CellGroup,Icon,NavBar,Search, DropdownMenu, DropdownItem
    },
    props: {},
    data() {
        return {

        }
    },
    watch: {},
    computed: {},
    methods: {
    },
    created() {},
    mounted() {}
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
