<template>
  <div id="app">
    <transition name="page">
      <keep-alive :include="aliveComponents">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      aliveComponents: [],
    };
  },
  components: {},
};
</script>

<style>
/* 声明全局字体样式 */
@font-face {
  font-family: "HanYiLiLiangHeiJian"; /* 字体名称 */
  src: url("./assets/fonts/HanYiLiLiangHeiJian.ttf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "PingFang Bold"; /* 字体名称 */
  src: url("./assets/fonts/PingFang Bold.ttf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "PingFang SC"; /* 字体名称 */
  src: url("./assets/fonts/PingFang Light.ttf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "PingFang Medium"; /* 字体名称 */
  src: url("./assets/fonts/PingFang Medium.ttf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "PingFang Regular"; /* 字体名称 */
  src: url("./assets/fonts/PingFang Regular.ttf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "Radikal"; /* 字体名称 */
  src: url("./assets/fonts/Radikal Medium.otf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "DIN-Black"; /* 字体名称 */
  src: url("./assets/fonts/DIN-Black.otf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "DIN-Bold"; /* 字体名称 */
  src: url("./assets/fonts/DIN-Bold.otf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "DIN-Light"; /* 字体名称 */
  src: url("./assets/fonts/DIN-Light.otf"); /* 字体文件相对路径 */
}
@font-face {
  font-family: "DIN-Medium"; /* 字体名称 */
  src: url("./assets/fonts/DIN-Medium.otf"); /* 字体文件相对路径 */
}
#app {
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
  background: #dbf2fc;
}
body {
  background: #dbf2fc !important;
}
/* 页面切换动画 **/
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s;
}
.page-enter,
.page-leave-active {
  opacity: 0;
}
.Loadingwrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.van-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
