import axios from "../../request/http.js";
import { Toast } from "vant";
export const getDetail = async () => {
  // 获取详情
  axios({
    url: "/api/my/index",
    method: "GET",
  }).then((res) => {
    if (res?.code == 1) {
      let data = res.data;
      localStorage.setItem("moneyInfo", JSON.stringify(data));
    }
  });
};

export const copyText = (text) => {
  let inputDom = document.createElement("textarea");
  document.body.appendChild(inputDom);
  inputDom.value = text;
  inputDom.select();
  inputDom.focus();
  document.execCommand("copy");
  document.body.removeChild(inputDom);
  Toast("copy success");
};
