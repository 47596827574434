<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">How to make money</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="item-2">How can we use this APP to make money?</div>
        <div class="item-3">
          1: Generate income by purchasing equipment. Please see the following
          pictures for specific income.
        </div>
        <div class="item-3">2: Earn income by inviting friends,</div>
        <div class="item-3">
          At the same time, you can get 15% cash back on your friends’
          investment amount.
        </div>
        <div class="item-3">
          You can also get up to 18% commission reward for directly promoting
          subordinates to the third level.
        </div>
        <div class="item-3">Top-up and withdrawal issues</div>
        <div class="item-3">
          1. The minimum recharge amount is 500 RS. Recharge amounts below 500
          RS will not be included in your account balance.
        </div>
        <div class="item-3">
          2. Make sure that the amount of currency you deposit on the Green
          Bicycle platform in the future is the same as the amount you transfer
          from mobile banking, otherwise
        </div>
        <div class="item-3">Will cause financial losses.</div>
        <div class="item-3">
          3. Once you have completed the payment process for your copied IBAN
          number, click the "Done" button.
        </div>
        <div class="item-3">
          4. IMPORTANT: Make sure to click the "Done" button
        </div>
        <div class="item-3">
          5. It takes 15 minutes for the investment to be reflected in your
          account, and the average investment from different banks is reflected
          in your account within 30 minutes
        </div>
        <div class="item-3">
          6. After the recharge is completed, please wait 1-10 minutes for it to
          automatically arrive in your account. Please wait patiently.
        </div>
        <div class="item-3">
          7. The minimum withdrawal amount is 200RS, except for special
          circumstances.
        </div>
        <div class="item-3">
          8. Please fill in your withdrawal information carefully when
          withdrawing money. Please do not fill in the wrong information. If you
          fill in the wrong information, the platform will not be responsible if
          the withdrawal does not arrive in your account.
        </div>
        <div class="item-3">
          9. If your first and last name are not filled in correctly, our
          company will not be responsible for any monetary losses after the
          shooting is successful.
        </div>
        <div class="item-3">Example: Your name is Muhammad Ali</div>
        <div class="item-3">Correct spelling: Muhammad Ali</div>
        <div class="item-3">
          Spelling errors: Muhammedali, Muhammed, muhammedali
        </div>
        <div class="item-3">
          10. Withdrawal time is from Monday to Sunday from 10:00 am to 6:00 pm.
          Each account can be withdrawn once per day.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Grid,
  GridItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  Field,
  Form,
  ActionSheet,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Grid,
    GridItem,
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    Field,
    Form,
    ActionSheet,
  },
  props: {},
  data() {
    return {
      feiJiUrl: "",
      whatsAppUrl: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // 获取详情
      this.$axios({
        url: "/api/my/online",
        method: "GET",
        data: {},
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          this.feiJiUrl = res?.data?.Feijiqun || "";
          this.whatsAppUrl = res?.data?.onlineUrl || "";
        }
      });
    },
    goPage(type) {
      if (type == "telegram") {
        window.open(`${this.feiJiUrl}`);
      }
      if (type == "whatsApp") {
        window.open(`${this.whatsAppUrl}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
