export default {
  method: "post",
  // 基础url前缀
  //baseURL: 'https://art.33ej.cn',
  //baseURL: 'https://art.asfgwsr.com',
  //baseURL: 'https://art.asfgwsr.in',
  // baseURL: 'https://art.williammall.net',
  baseURL: "https://art.airstarlink.com",
  // 请求头信息
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
  // 参数
  data: {},
  // 设置超时时间
  timeout: 10000,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: "json",
};
