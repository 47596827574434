<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">About us</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="item-3">
          It is a service of the space service company SpaceX that provides
          high-speed Internet access services covering the world through a
          constellation of low-Earth orbit satellites. As of July 23, 2022,
          Starlink services are available in sixty countries and regions.
        </div>
        <div class="item-3">
          In 2015, SpaceX CEO Elon Musk announced the launch of a space
          high-speed Internet plan in Seattle: the Starlink project. With
          performance that far exceeds that of traditional satellite Internet
          and a global network that is not limited by terrestrial
          infrastructure, Starlink can provide high-speed Internet services to
          locations where network service is unreliable, expensive, or
          completely unavailable. In addition, it may also end Internet blocking
          exists in today’s world. Designed to provide high-speed Internet
          services to everyone in the world. The purpose of the Starlink project
          is to develop a "global satellite Internet system" that can be used in
          environments such as Mars to deploy communication infrastructure
          within the solar system.
        </div>
        <div class="item-3">
          On February 22, 2018, SpaceX successfully launched a Falcon 9 carrier
          rocket from Vandenberg Air Force Base in California, USA, and sent two
          small experimental communication satellites into orbit, thus starting
          the Starlink project. On October 22, 2019, SpaceX CEO Elon Musk
          successfully tweeted through Starlink and stated that Starlink can
          provide space-based Internet services. Starlink aims to have service
          in the northern United States and Canada by 2020, and expand its
          service to nearly the entire globe by 2021. As of February 2023, the
          number of Starlink satellites in orbit has exceeded 3,500. At the same
          time, SpaceX also proposed the second-generation Starlink plan, which
          will rely on SpaceX starships to launch and form a satellite
          constellation of 29,988 satellites.
        </div>
        <div class="item-3">
          SpaceX plans to deploy close to 12,000 satellites in three orbits by
          the mid-2020s: first about 1,600 satellites in a 550-kilometer orbit,
          then about 2,800 Ku-band and Ka-band satellites in a 1,150-kilometer
          orbit, and finally at 340 Approximately 7,500 V-band satellites are
          deployed in orbits of 1.5 km. The entire plan is expected to require
          approximately $10 billion in expenditures.
        </div>
        <div class="item-3">
          In February 2022, the American technology website "The Verge" reported
          on Starlink's newly launched Starlink Premium service. The monthly
          rental of this service is US$499, and the supporting hardware fee is
          US$2,500. The uplink speed provided by this service is between
          150-500Mbps. Between, the latency is 20 to 40 milliseconds. But now
          this service may be replaced by the Starlink Business service.
          According to the Starlink official website, Starlink Business can
          provide communication guarantees in all-weather and severe weather
          environments, with a downlink speed of 150-350Mbps and a delay of 20
          to 40 milliseconds.
        </div>
        <div class="item-3">
          SpaceX aims to bring bandwidth internet connectivity to underserved
          areas of the planet and provide affordable service to urban areas. The
          company said cash flow from selling satellite internet services will
          be necessary to fund its Mars plans.
        </div>
        <div class="item-3">
          In early 2015, two space entrepreneurs announced Internet satellite
          investments in the same week. In addition to SpaceX CEO Elon Musk
          announcing the project, later known as Starlink, serial entrepreneur
          Richard Branson announced an investment in OneWeb, a similar satellite
          network project, With approximately 700 planned satellites, they have
          purchased communications frequency licenses for their broadcast
          spectrum.
        </div>
        <div class="item-3">
          After previous satellite-to-consumer space ventures failed, satellite
          industry consultant Roger Rusch said in 2015: "The odds of a
          successful business being built from this are slim."[98] Musk has
          publicly acknowledged this Commercial Reality, and said in mid-2015
          that he wanted to avoid overextending the company while working to
          develop such a technically complex space communications system, saying
          the pace of their development was being measured. Still, internal
          documents leaked in February 2017 indicate that SpaceX expects revenue
          from its satellite constellation to exceed $30 billion by 2025, while
          revenue from its launch business is expected to reach $5 billion that
          same year.
        </div>
        <div class="item-3">
          In February 2015, financial analysts questioned established operators
          of geosynchronous orbit communications satellite fleets about how they
          planned to respond to competitive threats from SpaceX and OneWeb LEO
          communications satellites. [102] In October, SpaceX President Gwen
          Shotwell said that while development was continuing, the business case
          for long-term deployment of an operational satellite network was still
          in its early stages.
        </div>
        <div class="item-3">
          In 2015, court documents revealed that SpaceX partnered with wireless
          chip maker Broadcom. Five key engineers subsequently left to join
          SpaceX, leading Broadcom to file a lawsuit accusing SpaceX of
          "stealing our top talent." In March, an Orange County judge denied
          Broadcom's multiple restraining order requests.
        </div>
        <div class="item-3">
          With the first launch of the first 60 satellites in the operational
          constellation in 2019, SpaceX said the constellation will need 420
          satellites to achieve broadband network coverage of small areas of the
          Earth, while 780 of the first 1,600 will be needed to provide moderate
          coverage.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Grid,
  GridItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  Field,
  Form,
  ActionSheet,
} from "vant";
export default {
  name: "homeProject",
  components: {
    Grid,
    GridItem,
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    Field,
    Form,
    ActionSheet,
  },
  props: {},
  data() {
    return {
      feiJiUrl: "",
      whatsAppUrl: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // 获取详情
      this.$axios({
        url: "/api/my/online",
        method: "GET",
        data: {},
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          this.feiJiUrl = res?.data?.Feijiqun || "";
          this.whatsAppUrl = res?.data?.onlineUrl || "";
        }
      });
    },
    goPage(type) {
      if (type == "telegram") {
        window.open(`${this.feiJiUrl}`);
      }
      if (type == "whatsApp") {
        window.open(`${this.whatsAppUrl}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
