import axios from './http.js'
const api = {}
 //登录
api.Login = params => {
    return axios({
        url: '/api/user/login',
        method: 'POST',
        data:params
    })
}

//登录 唯一设备
api.TCLogin = params => {
    return axios({
        url: '/api/user/tc_login',
        method: 'POST',
        data:params
    })
}
//提现佣金
api.MoneyChange = params => {
    return axios({
        url: '/api/user/money_change',
        method: 'POST',
        data:params
    })
}

 //赢得 当前开奖信息
api.NewInfo = params => {
    return axios({
        url: '/api/project/game_now',
        method: 'POST',
        data:params
    })
}

 //赢得 开奖记录
api.Kjrecord = params => {
    return axios({
        url: '/api/project/game_history',
        method: 'POST',
        data:params
    })
}

 //赢得 开奖记录
api.AddOrder = params => {
    return axios({
        url: '/api/project/add_order',
        method: 'POST',
        data:params
    })
}


 //赢得 下注记录
api.OrderList = params => {
    return axios({
        url: '/api/project/order_list',
        method: 'POST',
        data:params
    })
}

 //首页 商品列表
api.GoodsList = params => {
    return axios({
        url: '/api/goods/goods_list',
        method: 'POST',
        data:params
    })
}

 //首页 商品详情
api.GoodsDetail = params => {
    return axios({
        url: '/api/goods/goods_detail',
        method: 'POST',
        data:params
    })
}

 //我的 我的信息
api.MineInfo = params => {
    return axios({
        url: '/api/my/index',
        method: 'POST',
        data:params
    })
}

 //我的 修改昵称
api.NickName = params => {
    return axios({
        url: '/api/my/change_nickname',
        method: 'POST',
        data:params
    })
}

 //我的 提交建议 Complaints & Suggestions
api.AddSuggestion = params => {
    return axios({
        url: '/api/my/add_suggestion',
        method: 'POST',
        data:params
    })
}

 //我的 建议列表 Complaints & Suggestions
api.SuggestionList = params => {
    return axios({
        url: '/api/my/suggestion_list',
        method: 'POST',
        data:params
    })
}

 //我的 添加/修改银行卡 Bank Card
api.AddBankCard = params => {
    return axios({
        url: '/api/my/add_bank_card',
        method: 'POST',
        data:params
    })
}

 //添加用户固定红包 Red Envelope code new add 2020-12-27
api.AddRedEnvelope = params => {
    return axios({
        url: '/api/redenvelope/add_red_envelope',
        method: 'POST',
        data:params
    })
}

 //用户红包详情页面 Red Envelope code new add 2020-12-27
api.RedEnvelopeDetail = params => {
    return axios({
        url: '/api/redenvelope/red_envelope_detail',
        method: 'POST',
        data:params
    })
}

 //领取用户红包页面 Red Envelope code new add 2020-12-27
api.GetRedEnvelope = params => {
    return axios({
        url: '/api/redenvelope/get_red_envelope',
        method: 'POST',
        data:params
    })
}

 //用户红包列表 Red Envelope code new add 2020-12-27
api.RedEnvelopeList = params => {
    return axios({
        url: '/api/redenvelope/red_envelope_list',
        method: 'POST',
        data:params
    })
}

 //我的 银行卡列表 Bank Card
api.BankCardList = params => {
    return axios({
        url: '/api/my/bank_card_list',
        method: 'POST',
        data:params
    })
}

 //我的 银行卡详情 （用于修改） Bank Card
api.CardInfo = params => {
    return axios({
        url: '/api/my/card_info',
        method: 'POST',
        data:params
    })
}

 //我的 删除银行卡 Bank Card
api.DeleteCard = params => {
    return axios({
        url: '/api/my/del_card',
        method: 'POST',
        data:params
    })
}

 //我的 分享奖励 Promotion
api.MyPromotion = params => {
    return axios({
        url: '/api/my/my_promotion',
        method: 'POST',
        data:params
    })
}

 //我的 下级人员 Promotion Apply Record
api.MyChildren = params => {
    return axios({
        url: '/api/my/my_children',
        method: 'POST',
        data:params
    })
}
//我的 二级人员
api.MyTwoChildren = params => {
    return axios({
        url: '/api/my/x_x_ji',
        method: 'POST',
        data:params
    })
}
//我的 三级人员
api.MyThreeChildren = params => {
    return axios({
        url: '/api/my/x_x_x_ji',
        method: 'POST',
        data:params
    })
}

 //我的 奖励记录 Promotion Bonus Record
api.BonusRecord = params => {
    return axios({
        url: '/api/my/bonus_record',
        method: 'POST',
        data:params
    })
}

 //红包页面 Red Envelopes
api.HongbaoDetail = params => {
    return axios({
        url: '/api/hongbao/hongbao_detail',
        method: 'POST',
        data:params
    })
}

 //领取红包 Red Envelopes
api.GetHongbao = params => {
    return axios({
        url: '/api/hongbao/get_hongbao',
        method: 'POST',
        data:params
    })
}

 //注册 Register
api.Register = params => {
    return axios({
        url: '/api/user/register',
        method: 'POST',
        data:params
    })
}

 //注册发送验证码 Register
api.AliSend = params => {
    return axios({
        url: '/api/sms/ali_send',
        method: 'POST',
        data:params
    })
}

 //找回密码发送验证码 Forgotpass
api.ResetSend = params => {
    return axios({
        url: '/api/sms/reset_send',
        method: 'POST',
        data:params
    })
}

 //找回密码 Forgotpass
api.ResetPwd = params => {
    return axios({
        url: '/api/user/reset_pwd',
        method: 'POST',
        data:params
    })
}

 //提现页面信息 Withdrawal
api.WithdrawPage = params => {
    return axios({
        url: '/api/money/withdraw_page',
        method: 'POST',
        data:params
    })
}

 //提现 Withdrawal
api.Withdraw = params => {
    return axios({
        url: '/api/money/withdraw',
        method: 'POST',
        data:params
    })
}

 //提现记录 WithdrawalRecord
api.WithdrawLog = params => {
    return axios({
        url: '/api/money/withdraw_log',
        method: 'POST',
        data:params
    })
}

 //充值 Recharge
api.RechargePay = params => {
    return axios({
        url: '/api/money/pay',
        method: 'POST',
        data:params
    })
}

 //充值记录 RechargeRecord
api.RechargeLog = params => {
    return axios({
        url: '/api/money/recharge_log',
        method: 'POST',
        data:params
    })
}

 //签到信息 SignIn
api.SignIn = params => {
    return axios({
        url: '/api/user/signin_page',
        method: 'POST',
        data:params
    })
}

 //签到 SignIn
api.GoSignIn = params => {
    return axios({
        url: '/api/user/signin',
        method: 'POST',
        data:params
    })
}

 //流水列表 Transaction
api.Transaction = params => {
    return axios({
        url: '/api/money/user_money_log',
        method: 'POST',
        data:params
    })
}

 //利息
 api.Interest = params => {
    return axios({
        url: '/api/money/user_lx_log',
        method: 'POST',
        data:params
    })
}

 //首充奖励
 api.Reward = params => {
    return axios({
        url: '/api/money/user_jl_log',
        method: 'POST',
        data:params
    })
}
 //我的 公告信息 mine
api.GetNotice = params => {
    return axios({
        url: '/api/my/notice',
        method: 'POST',
        data:params
    })
}
//win区分盘
api.UserGameData = params => {
    return axios({
        url: '/api/project/my_games',
        method: 'POST',
        data:params
    })
}
export default api