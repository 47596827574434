<template>
  <div class="market-page">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar :border="false" placeholder safe-area-inset-top>
        <template #left>
          <div class="nav-title">Projects</div>
        </template>
        <template #right>
          <div class="navbar-right" @click="goPage">
            <div class="icon-label">Online Service</div>
            <Icon name="/images/kefu-icon.png" size="20" />
          </div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div
        class="wrapper-cell"
        style="
          background-image: url('/images/cell-bg.png');
          background-size: cover;
        "
      >
        <div class="cell-left">
          <div class="item-title">{{ investcount }}</div>
          <div class="item-subtitle">My device</div>
        </div>
        <div class="line"></div>
        <div class="cell-right" @click="$router.push('/project/income')">
          <div class="item-title">Rs.{{ income }}</div>
          <div class="item-subtitle">Device income</div>
        </div>
        <div class="cell-icon">
          <Icon name="arrow" size="20" />
        </div>
      </div>
      <div class="subtion">My project</div>
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: tabActive == index }"
          :style="
            tabActive == index
              ? 'background-image: url(/images/tabs-bg.png);background-size:cover'
              : ''
          "
          v-for="(item, index) in tabLst"
          :key="index"
          @click="onTabClick(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="project-wrapper" v-if="tabActive == 0">
        <div class="project-lsit" v-for="(item, index) in list" :key="index">
          <CellGroup :border="false" center>
            <template #title>
              <div class="title">{{ item.title }}</div>
            </template>
            <Cell :border="false">
              <template #icon>
                <img class="project-img" :src="baseUrl + item.img" />
              </template>
              <template #title>
                <div class="title-items">
                  <div class="item-left">Daily income:</div>
                  <div class="item-right">{{ item.dayIncome }}</div>
                </div>
                <div class="title-items">
                  <div class="item-left">Hourly income:</div>
                  <div class="item-right">Rs.{{ item.rate }}</div>
                </div>
                <div class="title-items">
                  <div class="item-left">Working period：</div>
                  <div class="item-right">
                    {{ item.obtainsum }}/{{ item.syqs }}
                  </div>
                </div>
                <div class="title-items">
                  <div class="item-left">Income：</div>
                  <div class="item-right">Rs.{{ item.monIncome }}</div>
                </div>
                <div class="title-items">
                  <div class="item-left">Total income:</div>
                  <div class="item-right orange">Rs.{{ item.income }}</div>
                </div>
              </template>
            </Cell>
          </CellGroup>
        </div>
      </div>
      <div class="project-wrapper" v-if="tabActive == 1">
        <div
          class="project-lsit"
          v-for="(item, index) in expirelist"
          :key="index"
        >
          <CellGroup :border="false" center>
            <template #title>
              <div class="title">{{ item.title }}</div>
            </template>
            <Cell :border="false">
              <template #icon>
                <img class="project-img" :src="baseUrl + item.img" />
              </template>
              <template #title>
                <div class="title-items">
                  <div class="item-left">Daily income:</div>
                  <div class="item-right">{{ item.dayIncome }}</div>
                </div>
                <div class="title-items">
                  <div class="item-left">Hourly income:</div>
                  <div class="item-right">Rs.{{ item.rate }}</div>
                </div>
                <div class="title-items">
                  <div class="item-left">Working period：</div>
                  <div class="item-right">
                    {{ item.obtainsum }}/{{ item.syqs }}
                  </div>
                </div>
                <div class="title-items">
                  <div class="item-left">Income：</div>
                  <div class="item-right">Rs.{{ item.monIncome }}</div>
                </div>
                <div class="title-items">
                  <div class="item-left">Total income:</div>
                  <div class="item-right orange">Rs.{{ item.income }}</div>
                </div>
              </template>
            </Cell>
          </CellGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Icon, NavBar } from "vant";
import config from "../../../../request/config";
export default {
  name: "homeProject",
  components: {
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
  },
  data() {
    return {
      tabActive: 0,
      tabLst: [{ name: "Purchased device" }, { name: "Already expired" }],
      investcount: "",
      income: "",
      list: [],
      expirelist: [],
      baseUrl: "",
    };
  },
  mounted() {
    this.baseUrl = config.baseURL;
    this.getProjectInfo();
  },
  methods: {
    onTabClick(item, index) {
      this.tabActive = index;
    },
    getProjectInfo() {
      this.$axios({
        url: "/api/my/myinvest",
        method: "POST",
      }).then((res) => {
        console.log(res);
        if (res?.code == 1) {
          this.investcount = res?.data?.investcount ?? 0;
          this.income = res?.data?.income ?? 0;
          this.list = res?.data?.list || [];
          if (this.list.length > 0) {
            this.list.forEach((item) => {
              item.dayIncome = Number(item.rate * 24).toFixed(2);
              item.monIncome = Number(item.rate * 24 * item.day).toFixed(2);
            });
          }
          this.expirelist = res?.data?.expirelist || [];
          if (this.expirelist.length > 0) {
            this.expirelist.forEach((item) => {
              item.dayIncome = Number(item.rate * 24).toFixed(2);
              item.monIncome = Number(item.rate * 24 * item.day).toFixed(2);
            });
          }
        }
      });
    },
    goPage() {
      this.$router.push("/user/customerService");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
