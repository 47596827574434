<template>
  <div class="container">
    <div
      class="header"
      style="
        background-image: url('/images/blue-bg.png');
        background-size: cover;
      "
    >
      <NavBar
        :border="false"
        placeholder
        safe-area-inset-top
        @click-left="$router.back()"
      >
        <template #left>
          <Icon name="/images/navbar-left-icon.png" size="22" />
        </template>
        <template #title>
          <div class="nav-title">My Bill</div>
        </template>
      </NavBar>
    </div>
    <div class="wrapper">
      <div class="tabs">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="It's the end"
          loading-text="loading"
          @load="onLoad"
        >
          <div class="cell" v-for="(item, index) of tabData" :key="index">
            <Cell :border="false">
              <template #icon>
                <Icon name="/images/buy-icon.png" size="44" />
              </template>
              <template #title>
                <div class="title">{{ item.memo }}</div>
              </template>
              <template #label>
                <div class="label">{{ item.date }}</div>
              </template>
              <template #right-icon>
                <div class="value">{{ item.after }}</div>
              </template>
            </Cell>
          </div>
        </List>
        <Tabs
          v-model="tabCurrent"
          title-active-color="#2E60F3"
          title-inactive-color="#222222"
          background="transparent"
          v-if="false"
        >
          <Tab title="All">
            <div class="cell" v-for="(item, index) of tabData" :key="index">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/buy-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value">-1200.0000</div>
                </template>
              </Cell>
            </div>

            <Divider>— It's the end —</Divider>
          </Tab>
          <!-- <Tab title="Buy">
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/buy-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value">-1200.0000</div>
                </template>
              </Cell>
            </div>
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/buy-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value">-1200.0000</div>
                </template>
              </Cell>
            </div>
          </Tab>
          <Tab title="Withdraw">
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/cash-back-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value in">+1200.0000</div>
                </template>
              </Cell>
            </div>
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/cash-back-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value in">+1200.0000</div>
                </template>
              </Cell>
            </div>
          </Tab>
          <Tab title="Recharge">
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/withdraw-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value">-4500.0000</div>
                </template>
              </Cell>
            </div>
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/withdraw-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value">-4500.0000</div>
                </template>
              </Cell>
            </div>
          </Tab>
          <Tab title="Cash back">
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/recharge-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value in">+500.0000</div>
                </template>
              </Cell>
            </div>
            <div class="cell">
              <Cell :border="false">
                <template #icon>
                  <Icon name="/images/recharge-icon.png" size="44" />
                </template>
                <template #title>
                  <div class="title">Buy Liebherr tunnel</div>
                </template>
                <template #label>
                  <div class="label">2023-09-15 9:18:47</div>
                </template>
                <template #right-icon>
                  <div class="value in">+500.0000</div>
                </template>
              </Cell>
            </div>
          </Tab> -->
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  NavBar,
  Divider,
  List,
} from "vant";
import dayjs from "dayjs";
export default {
  name: "homeProject",
  components: {
    Button,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Icon,
    NavBar,
    Divider,
    List,
  },
  props: {},
  data() {
    return {
      tabCurrent: 0,
      tabLst: [
        { name: "All", icon: "/images/blue-bg.png" },
        { name: "Buy", icon: "/images/blue-bg.png" },
        { name: "Withdraw", icon: "" },
        { name: "Recharge", icon: "" },
        { name: "Cash back", icon: "" },
      ],
      tabData: [],
      loading: false,
      finished: false,
      currentPage: 1,
    };
  },
  mounted() {
    console.log(dayjs(new Date()));
    this.getDetail();
  },
  methods: {
    // onLoad() {
    //   this.loading = false;
    //   // 数据全部加载完成
    //   if (this.tabData.length >= 40) {
    //     this.finished = true;
    //   } else {
    //     this.currentPage++;
    //     this.getDetail();
    //   }
    // },
    getDetail() {
      // 获取详情
      this.loading = true;
      let params = {
        page: this.currentPage,
        limit: 10,
      };
      this.$axios({
        url: "/api/money/user_money_log",
        method: "GET",
        params,
      }).then((res) => {
        this.loading = false;
        if (res?.code == 1) {
          this.tabData = res?.data?.list || [];
          this.tabData.forEach((item) => {
            item.date = dayjs(item.createtime).format("YYYY-MM-DD HH:mm:ss");
          });
          if (this.tabData.length >= res?.data?.count) {
            this.finished = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
